import {useDefaultValidator, useFormMessages, useSuggestState} from 'lib/finalForm/hooks';
import {BaseFieldProps, SuggestItem} from 'lib/finalForm/types';
import {getErrorMessage} from 'lib/finalForm/utils';
import {TestIdProp} from 'lib/testing/types';
import React, {useCallback} from 'react';
import {useField, useForm, useFormState} from 'react-final-form';
import {InputAutocomplete, InputAutocompleteTestId} from 'uikit/InputAutocomplete';

export type SuggestFieldTestId = InputAutocompleteTestId;

type Props = TestIdProp<SuggestFieldTestId> & BaseFieldProps;

export function SuggestField({testId, name, label, required, placeholder, validate}: Props): React.ReactElement {
  const formApi = useForm();
  const formState = useFormState();
  const messages = useFormMessages();
  const requiredMessage = messages[name].required;
  const inputLabel = label || messages[name].label;
  const inputPlaceholder = placeholder || messages[name].placeholder;

  const handleValidate = useDefaultValidator({required, requiredMessage, validate});
  const {input, meta} = useField<string>(name, {validate: handleValidate});
  const {suggestions, onSuggest, onSelectSuggest, renderSuggestLabel} = useSuggestState();

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onSuggest) {
        onSuggest(e.target.value, name, formState.values);
      }

      input.onChange(e.target.value);
    },
    [name, onSuggest, input.onChange, formState.values], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleSelect = useCallback(
    (suggest: SuggestItem) => {
      if (onSelectSuggest) {
        onSelectSuggest(suggest, formApi);
      }
    },
    [onSelectSuggest, formApi],
  );

  return (
    <InputAutocomplete<SuggestItem>
      error={getErrorMessage(meta)}
      label={inputLabel}
      name={input.name}
      onBlur={input.onBlur}
      onChange={handleChange}
      onFocus={input.onFocus}
      onSelect={handleSelect}
      options={suggestions?.[name]}
      placeholder={inputPlaceholder}
      renderOptionLabel={renderSuggestLabel}
      testId={testId}
      value={input.value}
    />
  );
}
