import {useSmartReducer} from 'lib/hooks';
import React, {FormEventHandler, useCallback, useEffect} from 'react';
import {FormActionsContext, FormStateContext, FormTextsContext} from './contexts';
import {initFormState, formReducerMethods} from './reducer';
import {FieldsValues, FormProps} from './types';

export {InputField} from './InputField';
export {PhoneField} from './PhoneField';
export {TextareaField} from './TextareaField';
export {SelectField} from './SelectField';

export function Form<Values extends FieldsValues>(props: FormProps<Values>): React.ReactElement {
  const {children, className, error, onSubmit, testId, texts, formApiRef} = props;
  const [formState, formActions] = useSmartReducer(
    formReducerMethods,
    () => initFormState<Values>({texts: props.texts}),
    [],
  );

  const handleSubmit = useCallback<FormEventHandler>(
    (e) => {
      e.preventDefault();
      formActions.submit();
    },
    [formActions.submit], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    formActions.applyError(error);
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (formState.submit && onSubmit) {
      onSubmit(formState.values);
    }
  }, [formState.submit]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (formApiRef) {
      formApiRef.current = formActions;
    }
  }, [formActions]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form action='#' className={className} data-test-id={testId} noValidate onSubmit={handleSubmit}>
      <FormTextsContext.Provider value={texts}>
        <FormActionsContext.Provider value={formActions}>
          <FormStateContext.Provider value={formState}>{children}</FormStateContext.Provider>
        </FormActionsContext.Provider>
      </FormTextsContext.Provider>
    </form>
  );
}
