import { getEnumValues as y } from "../../ts-enum/index.js";
import { m as d, a as b, w as $, b as x, c as O, o, e as u, i as k, d as E, f as J } from "../../index-BRk4PrFb.mjs";
import { i as A } from "../../isEqual-COraf02A.mjs";
const V = (a) => a.kind === "DecoderError" && typeof a.at == "string" && typeof a.message == "string", h = (a) => Array.isArray(a), m = (a) => typeof a == "object" && a !== null && !h(a), P = (a) => {
  switch (typeof a) {
    case "string":
      return "a string";
    case "number":
      return "a number";
    case "boolean":
      return "a boolean";
    case "undefined":
      return "undefined";
    case "object":
      return a instanceof Array ? "an array" : a === null ? "null" : "an object";
    default:
      return JSON.stringify(a);
  }
}, f = (a, e) => `expected ${a}, got ${P(e)}`, l = (a) => a.map((e) => typeof e == "string" ? `.${e}` : `[${e}]`).join(""), g = (a, { at: e, ...t }) => ({
  at: a + (e || ""),
  ...t
}), r = class r {
  /**
   * The Decoder class constructor is kept private to separate the internal
   * `decode` function from the external `run` function. The distinction
   * between the two functions is that `decode` returns a
   * `Partial<DecoderError>` on failure, which contains an unfinished error
   * report. When `run` is called on a decoder, the relevant series of `decode`
   * calls is made, and then on failure the resulting `Partial<DecoderError>`
   * is turned into a `DecoderError` by filling in the missing information.
   *
   * While hiding the constructor may seem restrictive, leveraging the
   * provided decoder combinators and helper functions such as
   * `andThen` and `map` should be enough to build specialized decoders as
   * needed.
   */
  constructor(e) {
    this.run = (t) => d(
      (i) => ({
        at: `input${i.at || ""}`,
        input: t,
        kind: "DecoderError",
        message: i.message || "",
        extra: i.extra
      }),
      this.decode(t)
    ), this.runPromise = (t) => b(this.run(t)), this.runWithException = (t) => $(this.run(t)), this.map = (t) => new r((i) => x(t, this.decode(i))), this.andThen = (t) => new r((i) => O((n) => t(n).decode(i), this.decode(i))), this.where = (t, i) => this.andThen((n) => t(n) ? r.succeed(n) : r.fail(i)), this.decode = e;
  }
  /**
   * Decoder primitive that validates strings, and fails on all other input.
   */
  static string() {
    return new r(
      (e) => typeof e == "string" ? o(e) : u({ message: f("a string", e) })
    );
  }
  /**
   * Decoder primitive that validates numbers, and fails on all other input.
   */
  static number() {
    return new r(
      (e) => typeof e == "number" ? o(e) : u({ message: f("a number", e) })
    );
  }
  /**
   * Decoder primitive that validates booleans, and fails on all other input.
   */
  static boolean() {
    return new r(
      (e) => typeof e == "boolean" ? o(e) : u({ message: f("a boolean", e) })
    );
  }
  static constant(e) {
    return new r(
      (t) => A(t, e) ? o(e) : u({ message: `expected ${JSON.stringify(e)}, got ${JSON.stringify(t)}` })
    );
  }
  static object(e) {
    return new r((t) => {
      if (m(t) && e) {
        const i = {};
        for (const n in e)
          if (e.hasOwnProperty(n)) {
            const s = e[n].decode(t[n]);
            if (s.ok === !0)
              s.result !== void 0 && (i[n] = s.result);
            else return t[n] === void 0 ? u({ message: `the key '${n}' is required but was not present` }) : u(g(`.${n}`, s.error));
          }
        return o(i);
      }
      return m(t) ? o(t) : u({ message: f("an object", t) });
    });
  }
  static array(e) {
    return new r((t) => {
      if (h(t)) {
        if (e) {
          const i = (s, c) => d((w) => g(`[${c}]`, w), e.decode(s)), n = [];
          for (let s = 0; s < t.length; s++) {
            const c = i(t[s], s);
            if (k(c))
              return c;
            n.push(c.result);
          }
          return o(n);
        }
        return o(t);
      }
      return u({ message: f("an array", t) });
    });
  }
  static tuple(e) {
    return new r((t) => {
      if (h(t)) {
        if (t.length !== e.length)
          return u({
            message: `expected a tuple of length ${e.length}, got one of length ${t.length}`
          });
        const i = [];
        for (let n = 0; n < e.length; n++) {
          const s = e[n].decode(t[n]);
          if (s.ok)
            i[n] = s.result;
          else
            return u(g(`[${n}]`, s.error));
        }
        return o(i);
      }
      return u({ message: f(`a tuple of length ${e.length}`, t) });
    });
  }
  /**
   * Combines 2-8 decoders of disparate types into a decoder for the union of all
   * the types.
   *
   * If you need more than 8 variants for your union, it's possible to use
   * `oneOf` in place of `union` as long as you annotate every decoder with the
   * union type.
   *
   * Example:
   * ```
   * type C = {a: string} | {b: number};
   *
   * const unionDecoder: Decoder<C> = union(object({a: string()}), object({b: number()}));
   * const oneOfDecoder: Decoder<C> = oneOf(object<C>({a: string()}), object<C>({b: number()}));
   * ```
   */
  static union(...e) {
    return r.oneOf(...e);
  }
  static intersection(e, t, ...i) {
    return new r(
      (n) => [e, t, ...i].reduce(
        (s, c) => E(Object.assign, s, c.decode(n)),
        o({})
      )
    );
  }
  static timestampToDate() {
    return new r(
      (e) => typeof e == "number" ? o(new Date(e)) : u({ message: f("a timestamp", e) })
    );
  }
};
r.anyJson = () => new r((e) => o(e)), r.unknownJson = () => new r((e) => o(e)), r.dict = (e) => new r((t) => {
  if (m(t)) {
    const i = {};
    for (const n in t)
      if (t.hasOwnProperty(n)) {
        const s = e.decode(t[n]);
        if (s.ok === !0)
          i[n] = s.result;
        else
          return u(g(`.${n}`, s.error));
      }
    return o(i);
  }
  return u({ message: f("an object", t) });
}), r.optional = (e) => new r((t) => t === void 0 ? o(void 0) : e.decode(t)), r.nullable = (e) => new r((t) => t === null ? o(null) : e.decode(t)), r.oneOf = (...e) => new r((t) => {
  const i = [];
  for (let s = 0; s < e.length; s++) {
    const c = e[s].decode(t);
    if (c.ok === !0)
      return c;
    i[s] = c.error;
  }
  const n = i.map((s) => `at error${s.at || ""}: ${s.message}`).join('", "');
  return u({
    message: `expected a value matching one of the decoders, got the errors ["${n}"]`
  });
}), r.oneOfEnum = (e) => new r((t) => {
  const i = y(e);
  return i.includes(t) ? o(t) : u({ message: `Expected one of ${JSON.stringify(i)}, got ${JSON.stringify(t)}` });
}), r.withDefault = (e, t) => new r((i) => o(J(e, t.decode(i)))), r.valueAt = (e, t) => new r((i) => {
  let n = i;
  for (let s = 0; s < e.length; s++) {
    if (n === void 0)
      return u({
        at: l(e.slice(0, s + 1)),
        message: "path does not exist"
      });
    if (typeof e[s] == "string" && !m(n))
      return u({
        at: l(e.slice(0, s + 1)),
        message: f("an object", n)
      });
    if (typeof e[s] == "number" && !h(n))
      return u({
        at: l(e.slice(0, s + 1)),
        message: f("an array", n)
      });
    n = n[e[s]];
  }
  return d(
    (s) => n === void 0 ? { at: l(e), message: "path does not exist" } : g(l(e), s),
    t.decode(n)
  );
}), r.succeed = (e) => new r((t) => o(e)), r.fail = (e) => new r((t) => u({ message: e })), r.echo = (e) => new r((t) => e), r.lazy = (e) => new r((t) => e().decode(t));
let p = r;
export {
  p as Decoder,
  V as isDecoderError
};
