import {ErrorBoundary} from '@sentry/react';
import {AppEnv} from 'components/AppEnv';
import {config} from 'config';
import {ValueMap} from 'effector';
import {enableMapSet} from 'immer';
import {isEventsEnabled} from 'lib/analytics/events';
import {isGtmEnabled} from 'lib/analytics/utils';
import {BasePage} from 'lib/app/BasePage';
import {ErrorPage} from 'lib/app/ErrorPage';
import {loadMessages, handleIntlError} from 'lib/app/intl';
import {Client} from 'lib/client';
import {CurrencyContext} from 'lib/currency';
import {StateManager} from 'lib/effector';
import {isFunction, isRecord} from 'lib/guards';
import {getAcceptedLanguage} from 'lib/language';
import {LanguageContext} from 'lib/language/context';
import {Language} from 'lib/language/types';
import {commonLogger} from 'lib/logger';
import {safeDumpTransportResponse} from 'lib/logger/converters';
import {initSentry} from 'lib/logger/sentry';
import {extractPromocodeFromQuery} from 'lib/promocode';
import {reverseUrl, getPageNameByPathname} from 'lib/router';
import 'models';
import {isTransportResponse} from 'lib/transport/types';
import {extractUtmParameters, stringifyUtmParameters} from 'lib/utm';
import {loadRootCategoriesFx} from 'models/categories';
import {checkConsultationDialog} from 'models/consultation';
import {validateAndApplyPromocode} from 'models/promocode';
import {setServerDate} from 'models/serverDate';
import {$user, loadUserFx} from 'models/user';
import {AppContext, AppInitialProps, AppProps} from 'next/app';
import React, {useEffect, useMemo} from 'react';
import {IntlProvider} from 'react-intl';
import 'styles/globals.scss';
import 'styles/mui.scss';

enableMapSet();

type Messages = Record<string, string>;

type ExtendedComponentProps = {
  autoRegistrationDisabled?: boolean;
  loginRequired?: boolean;
};

type ExtendedAppInitialProps = AppInitialProps & {
  client?: Client;
  externalLink?: string;
  language: Language;
  messages?: Messages;
  store?: StateManager | ValueMap;
};

type ExtendedAppProps = AppProps &
  ExtendedAppInitialProps & {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Component: AppProps['Component'] & ExtendedComponentProps;
  };

type ExtendedAppContext = AppContext & {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Component: AppContext['Component'] & ExtendedComponentProps;
};

let clientSideClient: Client;
let clientSideStore: StateManager;

export default function MyApp(props: ExtendedAppProps) {
  const {language, router, Component, externalLink, pageProps} = props;

  // getInitialProps is called during server rendering and also during client-side page transitions
  // so we need to create client, store and messages only once after server render, that's why memo is used here
  const messages = useMemo(() => props.messages, []); // eslint-disable-line react-hooks/exhaustive-deps

  const client = useMemo(() => {
    if (props.client instanceof Client) {
      return props.client;
    } else if (isRecord(props.client)) {
      return new Client(props.client);
    }
    throw new Error('Client config is failed');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const store = useMemo(() => {
    if (props.store instanceof StateManager) {
      return props.store;
    }
    return new StateManager({client, language}, props.store);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (typeof window !== 'undefined') {
      clientSideClient = client;
      clientSideStore = store;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const user = store.getState($user);
    if (isGtmEnabled(user)) {
      client.analytics.enableGtm();
    }
    if (isEventsEnabled(client.config)) {
      client.analytics.enableEvents();
    }

    if (__CLIENT__) {
      if (config.releaseStage === 'production' || config.releaseStage === 'staging') {
        initSentry();
      }

      store.dispatch(checkConsultationDialog);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const currentPageName = getPageNameByPathname(router.pathname);

  return (
    <ErrorBoundary>
      <IntlProvider
        defaultLocale={config.defaultLocale}
        locale={language}
        messages={messages}
        onError={handleIntlError}
      >
        <LanguageContext.Provider value={language}>
          <CurrencyContext.Provider value={config.defaultCurrency}>
            <AppEnv env={{currentPageName}}>
              {currentPageName === 'error' ? (
                <ErrorPage baseUrl={client.config.baseUrl}>
                  <Component {...pageProps} />
                </ErrorPage>
              ) : (
                <BasePage
                  client={client}
                  externalLink={externalLink}
                  store={store}
                  timeoutedRegistrationEnabled={!Component.autoRegistrationDisabled}
                >
                  <Component {...pageProps} />
                </BasePage>
              )}
            </AppEnv>
          </CurrencyContext.Provider>
        </LanguageContext.Provider>
      </IntlProvider>
    </ErrorBoundary>
  );
}

MyApp.getInitialProps = async (appContext: ExtendedAppContext): Promise<ExtendedAppInitialProps> => {
  const {Component, ctx, router} = appContext;

  const isErrorPage = getPageNameByPathname(router.pathname) === 'error';
  const language = getAcceptedLanguage(router.locale);
  const preferences = {
    currency: config.defaultCurrency,
    language,
  };

  let clientInitialized = false;
  let client;
  let store;
  let pageProps;
  let messages;
  let externalLink;

  if (__SERVER__) {
    client = new Client({preferences, router}, ctx.req);
    client.cookies.language = preferences.language;
    store = new StateManager({client, language});
    store.client = client;

    try {
      await client.initialize();
      clientInitialized = true;
    } catch (err) {
      const cleanedError = isTransportResponse(err) ? safeDumpTransportResponse(err) : err;
      commonLogger.error('App: Failed to update or create device', {
        err: cleanedError,
        isErrorPage,
      });

      if (!isErrorPage) {
        if (isTransportResponse(err) && ctx.res && err.status === 429) {
          ctx.res.writeHead(err.status);
          ctx.res.end(JSON.stringify(err.body));
          return {
            language,
            pageProps: {},
          };
        }

        throw err;
      }
    }
  } else {
    client = clientSideClient;
    store = clientSideStore;
  }

  // enhance ctx
  ctx.client = client;
  ctx.store = store;

  if (__SERVER__) {
    // UTM Parameters and External Link
    let queryUtm: string | undefined;
    try {
      queryUtm = stringifyUtmParameters(extractUtmParameters(ctx.query));
    } catch (err) {
      commonLogger.error('UTM: Failed to stringify utm parameters', err);
    }

    if (queryUtm && queryUtm !== client.cookies.utmParameters) {
      client.cookies.utmParameters = queryUtm;
      externalLink = ctx.req?.url;
    }

    // Promocode
    const promocode = extractPromocodeFromQuery(ctx.query);

    if (clientInitialized) {
      // Initialize store
      await store.dispatch(loadUserFx);
      await store.dispatch(loadRootCategoriesFx);
      await store.dispatch(setServerDate, new Date());

      if (promocode) {
        await store.dispatch(validateAndApplyPromocode, promocode);
      }
    }

    messages = await loadMessages(language);
    client.cookies.applyToResponse(ctx.res!);
  }

  const user = store.getState($user);

  if (Component.loginRequired && user.anonymous) {
    const redirectUrl = reverseUrl.auth(router.asPath);
    if (ctx.res) {
      /* eslint-disable @typescript-eslint/naming-convention */
      ctx.res.writeHead(302, {Location: `/${language}${redirectUrl}`}).end();
      /* eslint-enable @typescript-eslint/naming-convention */
    } else {
      router.replace(redirectUrl);
    }
  } else if (isFunction(Component.getInitialProps)) {
    pageProps = await Component.getInitialProps(ctx);
  }

  return {
    client,
    externalLink,
    language,
    messages,
    pageProps,
    store,
  };
};
