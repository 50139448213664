import {useAppEnv} from 'lib/appEnv';
import {useClient} from 'lib/client';
import {useReportWebVitals} from 'next/web-vitals';

export const Perfomance = () => {
  const client = useClient();
  const appEnv = useAppEnv();
  useReportWebVitals((metric) => {
    if (metric.name === 'LCP') {
      const body = {
        metricName: metric.name,
        pageName: appEnv.currentPageName,
        value: metric.value,
      };
      client.localApi.post('/metrics', {
        body,
      });
    }
  });
  return null;
};
