import {isString} from 'lib/guards';
import React, {useCallback, useContext, useEffect, forwardRef} from 'react';
import {Input, InputBlurHandler, InputChangeHandler} from 'uikit/Input';
import {FormTextsContext, useFormStateContext, useFormActionsContext} from '../contexts';
import {InputField as InputFieldProps} from '../types';

export const InputField = forwardRef(
  (props: InputFieldProps, ref: React.ForwardedRef<HTMLInputElement>): React.ReactElement => {
    const {
      autoFocus,
      autoComplete,
      label,
      minLength,
      name,
      onBlur,
      onFocus,
      onChange,
      placeholder,
      testId,
      type,
      value,
      size,
    } = props;

    const texts = useContext(FormTextsContext);
    const {onFieldBlur, onFieldChange, addField, removeField} = useFormActionsContext();
    const {errors, values} = useFormStateContext();

    const handleInputBlur = useCallback<InputBlurHandler>(
      (event) => {
        onFieldBlur({name: event.target.name, type});

        if (onBlur) {
          onBlur(event);
        }
      },
      [onFieldBlur, type, onBlur],
    );

    const handleInputChange = useCallback<InputChangeHandler>(
      (event) => {
        onFieldChange({name: event.target.name, type, value: event.target.value});

        if (onChange) {
          onChange(event);
        }
      },
      [onFieldChange, type, onChange],
    );

    useEffect(() => {
      addField(props);

      return () => removeField(props);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (name in values && values[name] === value) {
        return;
      }

      onFieldChange({name, type, value: value ?? ''});
    }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

    // TODO: учесть, что value мб отсутствовать и нужно как-то инициализировать
    let inputValue;

    if (name in values) {
      inputValue = isString(values[name]) ? String(values[name]) : undefined;
    } else {
      inputValue = value;
    }

    const inputError = errors[name];

    return (
      <Input
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        disabled={props.disabled}
        error={inputError}
        label={label || texts?.labels?.[name]}
        minLength={minLength}
        name={name}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
        onFocus={onFocus}
        placeholder={placeholder || texts?.placeholders?.[name]}
        ref={ref}
        size={size}
        testId={testId}
        type={type}
        value={inputValue}
      />
    );
  },
);
