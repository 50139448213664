import {DependencyList, useEffect} from 'react';

type DebounceEffectCallback = () => void;

export function useDebounceEffect(effect: DebounceEffectCallback, deps?: DependencyList, wait = 0): void {
  useEffect(() => {
    if (wait === 0) {
      effect();
      return undefined;
    }

    const timerId = window.setTimeout(() => effect(), wait);
    return () => window.clearTimeout(timerId);
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}
