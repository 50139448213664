import cn from 'classnames';
import React, {useCallback, useMemo} from 'react';
import {Form} from 'react-final-form';
import {Actions} from './Actions';
import styles from './index.module.scss';
import {WizardReturnShape} from './useWizard';

export * from './useWizard';

type WizardStepProps = {
  children: React.ReactNode;
  className?: string;
};

type WizardChildren = React.ReactElement<WizardStepProps> | React.ReactElement<WizardStepProps>[];

export type WizardProps<FormValues> = Pick<WizardReturnShape, 'step' | 'goNext'> & {
  children: WizardChildren;
  initialValues?: FormValues;
  nextButton?: React.ReactNode;
  onNext?: (data: FormValues) => void | Promise<unknown>;
  onSubmit: (data: FormValues) => void;
  submitButton?: React.ReactNode;
};

export function Wizard<FormValues>({
  children,
  onSubmit,
  onNext,
  step,
  goNext,
  initialValues,
  nextButton,
  submitButton,
}: WizardProps<FormValues>): React.ReactElement {
  const elements = useMemo(() => React.Children.toArray(children) as React.ReactElement<WizardStepProps>[], [children]);

  const submit = useCallback(
    // eslint-disable-next-line consistent-return
    async (values: FormValues) => {
      if (step === elements.length - 1) {
        return onSubmit(values);
      }
      if (onNext) {
        try {
          await onNext(values);
        } catch (err) {
          return undefined;
        }
      }
      goNext();
    },
    [onSubmit, step, elements.length], // eslint-disable-line react-hooks/exhaustive-deps
  );
  const activeStep = elements[step];

  return (
    <Form<FormValues> initialValues={initialValues} onSubmit={submit}>
      {({handleSubmit, submitting, invalid, validating}) => (
        <form className={styles.root} onSubmit={handleSubmit}>
          <div className={cn(styles.step)} tabIndex={0}>
            {activeStep}
          </div>
          <Actions
            disabled={invalid}
            nextButton={nextButton}
            showSubmit={step === elements.length - 1}
            submitButton={submitButton}
            submitting={submitting}
            validating={validating}
          />
        </form>
      )}
    </Form>
  );
}

Wizard.Step = ({children, className}: WizardStepProps) => {
  return <div className={className}>{children}</div>;
};
