import {sample} from 'effector';
import {signOutFx, authSuccess} from '../auth';
import {updateUserFx} from '../ssRegistrationDialog';
import {$user, loadUserFx} from './index';

const userChangeTriggers = [loadUserFx.doneData, authSuccess, signOutFx.doneData, updateUserFx.doneData];

$user.on(userChangeTriggers, (state, user) => user);

sample({
  clock: userChangeTriggers,
  fn: (user) => {
    if (window) {
      // Variable for enhanced conversions in Google Tag Manager
      const {email, phone, country, firstName} = user;
      window.userProvidedData = {country, email, firstName, phone};
    }
  },
  source: $user,
});
