import {startBounceCheck} from 'lib/analytics/utils';
import {useClient} from 'lib/client';
import {useRouter} from 'next/router';
import {useEffect, useCallback, useRef} from 'react';

export const useBounceCheck = () => {
  const router = useRouter();
  const client = useClient();
  const {analytics} = client;

  const bounceCheck = useRef<ReturnType<typeof startBounceCheck> | undefined>();

  const pageLeft = useCallback(() => {
    bounceCheck.current?.forceSend('pageClosed');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const pageOpened = useCallback(() => {
    bounceCheck.current?.abort();
    bounceCheck.current = startBounceCheck(analytics, window.location.href);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'hidden') {
      bounceCheck.current?.forceSend('pageClosed');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    pageOpened();

    router.events.on('routeChangeStart', pageLeft);
    router.events.on('routeChangeComplete', pageOpened);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      router.events.off('routeChangeStart', pageLeft);
      router.events.off('routeChangeComplete', pageOpened);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
