import {useAnalytics} from 'lib/analytics';
import {useLanguage} from 'lib/language/hooks';
import {Language} from 'lib/language/types';
import {useCallback} from 'react';

export function useFormSuccessEvent() {
  const language = useLanguage();
  const analytics = useAnalytics();

  const onSuccess = useCallback(() => {
    if (language === Language.RU) {
      analytics.dataLayer({
        event: 'formSuccessSubmit',
      });
    }
  }, [language]); // eslint-disable-line react-hooks/exhaustive-deps

  return {onSuccess};
}
