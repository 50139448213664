import {config} from 'config';

export const getDefaultBaseUrl = (() => {
  let cachedOrigin: string | undefined;
  let preparedDefaultBaseUrl: URL | undefined;

  return (): URL => {
    if (!preparedDefaultBaseUrl || cachedOrigin !== config.defaultOrigin) {
      cachedOrigin = config.defaultOrigin;
      preparedDefaultBaseUrl = new URL(config.defaultOrigin);
    }

    return preparedDefaultBaseUrl;
  };
})();
