export enum CommunicationReason {
  CONSULTATION_CLAIM = 'consultationClaim',
  CONSULTATION_FROM_BLOG = 'consultationFromBlog',
  LOYALTY_PROGRAM = 'loyaltyProgram',
  // legacy
  PARTNERS_CONSULTATION = 'partnersConsultation',
  PARTNER_B2BASKET = 'b2basket',
  PARTNER_BIZNESKRUCHE = 'bizneskruche',
  PARTNER_KAK2C = 'kak2s',
  PARTNER_MONEYPLACE = 'moneyplace',
  PARTNER_MONEY_SELLERS = 'moneySellers',
  PARTNER_MP_STATS = 'mpStats',
  PARTNER_SELLER_EXPERT = 'sellerExpert',
  PARTNER_SELLPLUS = 'sellplus',
  PARTNER_SELSUP = 'selsup',
  PAYMENTS_CONSULTATION = 'paymentsConsultation',
  PAYMENTS_PARTNERS = 'paymentsPartners',
  REFERRAL_PROGRAM = 'referralProgram',
}

export enum RadarPossessionType {
  EXIST = 'exist',
  HAVE_NO_ANSWER = 'haveNoAnswer',
  NOT_EXIST_WILL_BUY = 'notExistWillBuy',
  NOT_EXIST_WONT_BUY = 'notExistWontBuy',
}

export enum QualifiedLeadType {
  CALL_CENTER = 'callCenter',
  QUALIFIED = 'qualified',
}

export enum HasProductImport {
  NO = 'false',
  YES = 'true',
}

export type CommunicationInteractionFields = {
  campaign?: string;
  source?: string;
  type?: string;
};

export type CommunicationRequest = CommunicationInteractionFields & {
  comment?: string;
  companyName?: string;
  email?: string;
  firstName?: string;
  hasLegalEntity?: boolean;
  hasProductImport?: boolean;
  importProducts?: string;
  phone: string;
  promocode?: string;
  radarPossessionType?: RadarPossessionType;
  reason: CommunicationReason;
};

export type CommunicationParams = {
  phone: string;
};

export type CommunicationResponse = {
  friendlyId: string;
  interactionId: string;
  popupRequestId?: string;
};

export type CommunicationState = CommunicationInteractionFields & {
  error?: unknown;
  lastReponse?: CommunicationResponse;
  opened: boolean;
  processing?: boolean;
  reason?: CommunicationReason;
  request?: CommunicationRequest;
  section: string;
  success?: boolean;
};

export type CommunicationOpenDialogParams = CommunicationInteractionFields & {
  pageUrl: string;
  reason: CommunicationReason;
  section: string;
};
