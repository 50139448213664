import { s as a } from "../../index-BRk4PrFb.mjs";
import { Decoder as t } from "../decoder/index.js";
import { isPositiveNumber as i } from "../../math/isPositiveNumber/index.js";
import { getEnumValues as m } from "../../ts-enum/index.js";
const { string: u } = t, { number: p } = t, y = (e) => t.string().where((n) => e.test(n), `string does not match ${e} pattern`), l = () => t.union(u(), p()).where((e) => !Number.isNaN(new Date(e).getTime()), "incorrect date format").map((e) => new Date(e)), S = ({ short: e } = {}) => l().map((n) => e ? n.toISOString().slice(0, 10) : n.toISOString()), f = () => t.string().where((e) => !Number.isNaN(Date.parse(e)), "incorrect date format").map((e) => Date.parse(e)), d = () => t.number().where((e) => i(e), "expected positive number"), { boolean: v } = t, { anyJson: O } = t, { unknownJson: j } = t, { constant: E } = t, { object: I } = t, { array: T } = t, k = (e) => t.array().map((n) => n.map(e.run)).map(a), { tuple: x } = t, { dict: b } = t, { optional: A } = t, { nullable: F } = t, { oneOf: g } = t, { oneOfEnum: J } = t, { union: P } = t, { intersection: V } = t, { withDefault: c } = t, { valueAt: z } = t, { succeed: K } = t, { fail: U } = t, { lazy: $ } = t, { timestampToDate: q } = t, B = () => g(f(), d()), C = (e) => c(null, e), G = (e) => c(void 0, e), H = (e) => t.object().map(
  (n) => Object.keys(e).reduce(
    (o, s) => e[s].run(n[s]).ok ? {
      ...o,
      [s]: n[s]
    } : o,
    {}
  )
), L = (e, n) => {
  const o = m(e);
  return b(n).where(
    // @ts-expect-error: enums satisfy the PropertyKey constraint by design
    (s) => Object.keys(s).every((r) => o.includes(r)),
    "expected keys to be of enum values"
    // @ts-expect-error: enums satisfy the PropertyKey constraint by design
  );
};
export {
  O as anyJson,
  T as array,
  v as boolean,
  E as constant,
  l as dateFromString,
  S as dateToIsoString,
  b as dict,
  L as dictEnum,
  U as fail,
  V as intersection,
  $ as lazy,
  F as nullable,
  p as number,
  I as object,
  g as oneOf,
  J as oneOfEnum,
  A as optional,
  d as positiveNumber,
  u as string,
  y as stringPattern,
  K as succeed,
  k as successesArray,
  H as successesKeys,
  B as timestamp,
  f as timestampFromString,
  q as timestampToDate,
  x as tuple,
  P as union,
  j as unknownJson,
  z as valueAt,
  c as withDefault,
  C as withDefaultNull,
  G as withDefaultUndefined
};
