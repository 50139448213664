import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Effect} from 'effector';
import {useEvent} from 'effector-react/ssr';
import {DependencyList, useCallback, useContext, useState} from 'react';
import {StateManagerContext} from './contexts';
import {StateManager} from './StateManager';

export {useStore, useEvent} from 'effector-react/ssr';

export function useStateManager(): StateManager {
  const stateManager = useContext(StateManagerContext);

  if (!stateManager) {
    throw new Error('No StateManager found, consider adding <StateManagerProvider> to app root');
  }

  return stateManager;
}

type InitialState<R, F> = {
  error: F | unknown;
  loaded: boolean;
  pending: boolean;
  result: R | undefined;
  state: DataState;
};

export type EffectTask<T, R, F> = {
  error: F | unknown;
  loaded: boolean;
  pending: boolean;
  perform: (payload: T) => void;
  reset: () => void;
  result: R | undefined;
  state: DataState;
};

const initialState = {
  error: false,
  loaded: false,
  pending: false,
  result: undefined,
  state: DataState.IDLE,
};

export function useEffectTask<T, R, F>(fx: Effect<T, R, F>, deps: DependencyList): EffectTask<T, R, F> {
  const eventFx = useEvent(fx);
  const [state, setState] = useState<InitialState<R, F>>(initialState);

  const reset = useCallback(() => setState(initialState), []);
  const perform = useCallback((payload: T) => {
    setState((currState) => ({
      ...currState,
      error: false,
      loaded: false,
      pending: true,
      result: undefined,
      state: DataState.LOADING,
    }));

    return eventFx(payload)
      .then((result) => {
        setState((currState) => ({
          ...currState,
          loaded: true,
          pending: false,
          result,
          state: DataState.LOADED,
        }));
      })
      .catch((queryError) => {
        setState((currState) => ({
          ...currState,
          error: queryError,
          loaded: false,
          pending: false,
          state: DataState.FAILED,
        }));
      });
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps

  return {...state, perform, reset};
}
