import {useRouter} from 'next/router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import {useEffect, useCallback} from 'react';

export const useProgressBar = () => {
  const router = useRouter();

  const progressStart = useCallback(() => {
    NProgress.start();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const progressDone = useCallback(() => {
    NProgress.done();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    NProgress.configure({showSpinner: false});
    router.events.on('routeChangeStart', progressStart);
    router.events.on('routeChangeComplete', progressDone);
    router.events.on('routeChangeError', progressDone);

    return () => {
      router.events.off('routeChangeStart', progressStart);
      router.events.off('routeChangeComplete', progressDone);
      router.events.off('routeChangeError', progressDone);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
