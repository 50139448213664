import {Currency, Money} from 'lib/pricing/types';

export enum ProductPriceType {
  BY_QUANTITY = 'distributedByQuantity',
  GENERAL = 'general',
  RANGE = 'range',
}

export type ProductPriceByRange = {
  ccy: Currency;
  max: number;
  min: number;
};

export type ProductPriceRange = {
  ccy: Currency;
  maxPrice?: number;
  maxQuantity?: number;
  minPrice: number;
  minQuantity?: number;
};

export type ProductPriceByQuantity = {
  price: Money;
  startQuantity: number;
};

export type ProductVariantPrice = {
  price: Money;
  pricePerItem: Money;
  quantity: number;
  variantId: string;
};

export type ProductPrices = {
  customizationThresholdPrice: Money;
  deliveryPrice: Money;
  icmsPrice: Money;
  minTotalPrice: Money;
  /**
   * @deprecated Field oldMinTotalPrice is deprecated, use customizationThresholdPrice instead.
   */
  oldMinTotalPrice?: Money;
  otherPrice: Money;
  pisAndCofinsPrice: Money;
  /**
   * @deprecated field is deprecated, use prices instead
   */
  priceRange?: ProductPriceByRange;
  /**
   * @deprecated field is deprecated
   */
  priceType: ProductPriceType;
  prices: ProductPriceRange[];
  /**
   * @deprecated field is deprecated, use prices instead
   */
  pricesByQuantity?: ProductPriceByQuantity[];
  productsPrice: Money;
  sellerPrice?: Money;
  totalPrice: Money;
  totalQuantity: number;
  variants: ProductVariantPrice[];
};
