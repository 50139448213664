import classNamesBind from 'classnames/bind';
import {buildMessage} from 'lib/intl';
import {ExtMessageDescriptor} from 'lib/intl/types';
import {TestIdProp} from 'lib/testing/types';
import {ElementSize} from 'lib/types/ElementSize';
import React, {useState, useCallback, useEffect} from 'react';
import {useIntl} from 'react-intl';
import styles from './index.module.scss';

const cn = classNamesBind.bind(styles);

export type TextareaTestId = {
  input: unknown;
  label: unknown;
};

export type TextareaBlurHandler = React.FocusEventHandler<HTMLTextAreaElement>;

export type TextareaFocusHandler = React.FocusEventHandler<HTMLTextAreaElement>;

export type TextareaChangeHandler = React.ChangeEventHandler<HTMLTextAreaElement>;

type InheritedPropNames =
  | 'rows'
  | 'name'
  | 'value'
  | 'minLength'
  | 'maxLength'
  | 'disabled'
  | 'readOnly'
  | 'id'
  | 'autoFocus';

type Props = Pick<JSX.IntrinsicElements['textarea'], InheritedPropNames> &
  TestIdProp<TextareaTestId> & {
    error?: string | ExtMessageDescriptor;
    label?: string | ExtMessageDescriptor;
    onBlur?: TextareaBlurHandler;
    onChange?: TextareaChangeHandler;
    onFocus?: TextareaFocusHandler;
    placeholder?: string | ExtMessageDescriptor;
    size?: ElementSize;
  };

/**
 * TODO: https://joom-team.atlassian.net/browse/PRO-5944
 * @deprecated use `import { Input } from "uikit/InputMui";` with multiline prop instead
 */
export function Textarea({
  testId,
  value,
  error,
  label,
  placeholder,
  onChange,
  size = 'medium',
  ...textareaProps
}: Props): React.ReactElement {
  const intl = useIntl();
  const [textareaValue, setTextareaValue] = useState(value);

  const empty = !textareaValue;
  const withError = Boolean(error);
  const labelValue = buildMessage(intl, error || label);
  const placeholderValue = buildMessage(intl, placeholder);
  const withLabel = Boolean(labelValue);
  const notEmpty = Boolean(textareaValue);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(e);
    }

    setTextareaValue(e.target.value);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (value !== textareaValue) {
      setTextareaValue(value);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <div
      className={cn('wrapper', `size-${size}`, {
        empty,
        notEmpty,
        withError,
        withLabel,
      })}
    >
      <textarea
        {...textareaProps}
        className={styles.textarea}
        data-test-id={testId?.input}
        onChange={handleChange}
        placeholder={placeholderValue}
        value={textareaValue}
      />
      <div className={styles.border} />
      <div className={styles.label} data-test-id={testId?.label}>
        {labelValue}
      </div>
    </div>
  );
}
