import {isString} from 'lib/guards';
import {InputType} from 'lib/input/types';
import {useMask, UseMaskOptions} from 'lib/mask';
import {TestIdProp} from 'lib/testing/types';
import React, {useRef, useEffect, useMemo, forwardRef, useState, useCallback} from 'react';
import {Input, InputProps, InputTestId} from 'uikit/Input';

function getType(type: InputType) {
  return type === 'phone' ? 'phone' : 'text';
}

export type Props = TestIdProp<InputTestId> &
  Omit<InputProps, 'placeholder' | 'value' | 'onChange'> & {
    fallbackMaskChar?: string;
    mask: string;
    onChange?(value: string): void;
    transformBeforeInput?(value: string): string;
    value?: string;
  };

export const InputMask = forwardRef<HTMLInputElement, Props>(
  (
    {type = 'text', mask, value, testId, fallbackMaskChar, transformBeforeInput, ...inputProps},
    ref,
  ): React.ReactElement => {
    const [focused, setFocused] = useState(false);
    const inputRef = useRef<HTMLInputElement>();
    /* eslint-disable react-hooks/exhaustive-deps */
    const options = useMemo<UseMaskOptions>(
      () => ({
        alwaysShowMask: false,
        fallback: fallbackMaskChar,
        initialValue: value,
        onBlur: inputProps.onBlur,
        onChange: inputProps.onChange,
        onFocus: inputProps.onFocus,
        onKeyDown: inputProps.onKeyDown,
        transformBeforeInput,
      }),
      [
        fallbackMaskChar,
        transformBeforeInput,
        inputProps.onChange,
        inputProps.onKeyDown,
        inputProps.onFocus,
        inputProps.onBlur,
      ],
    );
    /* eslint-enable react-hooks/exhaustive-deps */

    const {
      value: inputValue,
      selection,
      maskRef,
      updateValue,
      onChange,
      onKeyDown,
      onBlur,
      onFocus,
    } = useMask(mask, options);

    const placeholder = useMemo<string>(() => {
      if (maskRef.current) {
        return maskRef.current.getMask();
      }

      return '';
    }, [mask]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (inputRef.current) {
        // Fixes behavior in Safari.
        // If sets selection range on unfocused element
        // safari will always set focus in this field.
        if (focused) {
          const {selectionStart, selectionEnd} = inputRef.current;
          if (selectionStart !== selection.start || selectionEnd !== selection.end) {
            inputRef.current.setSelectionRange(selection.start, selection.end);
          }
        }
      }
    });

    useEffect(() => {
      if (isString(value) && value !== inputValue) {
        updateValue(value);
      }
    }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleFocus = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        if (onFocus) {
          onFocus(e);
        }

        setFocused(true);
      },
      [onFocus],
    );

    const handleBlur = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        if (onBlur) {
          onBlur(e);
        }

        setFocused(false);
      },
      [onBlur],
    );

    const setRef = useCallback((el: HTMLInputElement | null) => {
      inputRef.current = el || undefined;

      if (ref) {
        if ('current' in ref) {
          // eslint-disable-next-line no-param-reassign
          ref.current = el;
        } else {
          ref(el);
        }
      }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Input
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...inputProps}
        onBlur={handleBlur}
        onChange={onChange}
        onFocus={handleFocus}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        ref={setRef}
        testId={testId}
        type={getType(type)}
        value={inputValue}
        withMaskPlaceholder
      />
    );
  },
);
