import {useBounceCheck} from './useBounceCheck';
import {useInitialAnalyticsEvents} from './useInitialAnalyticsEvents';
import {useProgressBar} from './useProgressBar';
import {useRegistrationTimeout} from './useRegistrationTimeout';
import {useVisibilityChangeEvent} from './useVisibilityChangeEvent';

type Props = {
  externalLink: string | undefined;
  timeoutedRegistrationEnabled: boolean;
};

export const RootHooks = ({timeoutedRegistrationEnabled, externalLink}: Props) => {
  useInitialAnalyticsEvents({externalLink});
  useBounceCheck();
  useVisibilityChangeEvent();
  useRegistrationTimeout({enabled: timeoutedRegistrationEnabled});
  useProgressBar();
  return null;
};
