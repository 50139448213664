var t = {}, f;
function v() {
  if (f) return t;
  f = 1, Object.defineProperty(t, "__esModule", { value: !0 });
  function u() {
    for (var n = [], r = 0; r < arguments.length; r++)
      n[r] = arguments[r];
    return function() {
      for (var e = [], a = 0; a < arguments.length; a++)
        e[a] = arguments[a];
      return n.reduce(function(p, g, o) {
        var i = o === 0 ? p : [p];
        return g.apply(void 0, i);
      }, e);
    };
  }
  t.pipe = u;
  function s(n) {
    for (var r = [], e = 1; e < arguments.length; e++)
      r[e - 1] = arguments[e];
    return u.apply(void 0, r)(n);
  }
  return t.pipeWith = s, t;
}
var d = v();
export {
  d as t
};
