import {MIN_PASSWORD_LENGTH} from 'components/Auth/constants';
import {Form, InputField, PhoneField} from 'components/Form';
import {Link} from 'components/Link';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import {isFormValidationError} from 'lib/form/types';
import {reverseUrl} from 'lib/router';
import {TestIdProp} from 'lib/testing/types';
import React, {useCallback, useEffect} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {Button} from 'uikit/Button';
import {InputTestId} from 'uikit/Input';
import {formTexts} from '../messages';
import {SignTabs, SignTabsTestId} from '../SignTabs';
import {ViewChangeHandler, AuthViewName, SubmitHandler, SignUpByEmailCredential} from '../types';
import {View, ViewActions, ViewError, ViewExtraContent, ViewFields, ViewHeader, ViewTabs} from '../View';

const messages = defineMessages({
  button: {
    defaultMessage: 'Register',
    description: 'Registration form: "Register" button',
  },
  error: {
    defaultMessage: 'Check for errors and try again',
    description: 'Registration form: standard error',
  },
  policy: {
    defaultMessage: 'By registering, you agree with our <link>Privacy Policy</link>',
    description: 'Registration form: notification of consent to the data processing policy',
  },
  title: {
    defaultMessage: 'Sign In',
    description: 'Registration form: header',
  },
});

export type SignUpByEmailTestId = {
  form: {
    email: InputTestId;
    firstName: InputTestId;
    lastName: InputTestId;
    password: InputTestId;
    phone: InputTestId;
    repeatPassword: InputTestId;
    submit: unknown;
  };
  tabs: SignTabsTestId;
};

type Props = TestIdProp<SignUpByEmailTestId> & {
  error?: unknown;
  onSubmit: SubmitHandler<SignUpByEmailCredential>;
  onViewChange: ViewChangeHandler;
  pending?: boolean;
};

export function SignUpView({error, onSubmit, onViewChange, pending, testId}: Props): React.ReactElement {
  const analytics = useAnalytics();
  const appEnv = useAppEnv();
  const handleSubmit = useCallback(
    (data: SignUpByEmailCredential) => {
      onSubmit({
        ...data,
        source: 'main',
      });
    },
    [onSubmit],
  );

  const handlePolicyClick = useCallback(() => {
    analytics.sendEvent({
      payload: {
        pageUrl: window.location.href,
        position: 'body',
        source: appEnv.currentPageName,
      },
      type: 'policyClick',
    });
  }, [analytics, appEnv]);

  const validationError = isFormValidationError(error) ? error : undefined;
  const commonErrorVisible = Boolean(error && !validationError);

  useEffect(() => {
    analytics.sendEvent({
      payload: {
        pageUrl: window.location.href,
        source: appEnv.currentPageName,
      },
      type: 'signUpOpen',
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <View testId={testId}>
      <ViewHeader image='/illustrations/auth.svg' title={messages.title} />
      <ViewTabs>
        <SignTabs onViewChange={onViewChange} testId={testId?.tabs} view={AuthViewName.SIGN_UP} />
      </ViewTabs>
      <Form error={validationError} onSubmit={handleSubmit} testId={testId?.form} texts={formTexts}>
        <ViewFields>
          <InputField autoComplete='given-name' name='firstName' required testId={testId?.form.firstName} type='text' />
          <InputField autoComplete='family-name' name='lastName' required testId={testId?.form.lastName} type='text' />
          <InputField autoComplete='email' name='email' required testId={testId?.form.email} type='email' />
          <PhoneField autoComplete='phone' name='phone' required testId={testId?.form.phone} />
          <InputField
            autoComplete='new-password'
            minLength={MIN_PASSWORD_LENGTH}
            name='password'
            required
            testId={testId?.form.password}
            type='password'
          />
          <InputField
            autoComplete='new-password'
            equalTo='password'
            name='repeatPassword'
            required
            testId={testId?.form.repeatPassword}
            type='password'
          />
        </ViewFields>
        {commonErrorVisible && <ViewError error={messages.error} />}
        <ViewActions>
          <Button
            color='black'
            disabled={pending}
            label={messages.button}
            loading={pending}
            testId={testId?.form.submit}
            type='submit'
          />
        </ViewActions>
        <ViewExtraContent>
          <FormattedMessage
            {...messages.policy}
            values={{
              link: (content: React.ReactNode) => (
                <Link href={reverseUrl.policy()} onClick={handlePolicyClick} target='_blank'>
                  {content}
                </Link>
              ),
            }}
          />
        </ViewExtraContent>
      </Form>
    </View>
  );
}
