import {FormTexts} from 'components/Form/types';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import {CommunicationReason} from 'lib/communication/types';
import {isFormValidationError} from 'lib/form/types';
import {ExtMessageDescriptor} from 'lib/intl/types';
import {TestIdProp} from 'lib/testing/types';
import React, {useEffect} from 'react';
import {Dialog, DialogHeader} from 'uikit/Dialog';
import {DialogTestId} from 'uikit/Dialog/types';
import {ErrorView} from './ErrorView';
import {FormView, FormViewTestId} from './FormView';
import {SuccessView, SuccessViewTestId} from './SuccessView';
import {FormData, FormViewFields, SubmitHandler} from './types';

export type CommunicationDialogTestId = {
  dialog: DialogTestId;
  formView: FormViewTestId;
  successView: SuccessViewTestId;
};

const UNKNOWN_REASON = 'unknown';

type Props<Data> = TestIdProp<CommunicationDialogTestId> & {
  children?: React.ReactNode;
  error?: unknown;
  fields?: FormViewFields;
  formTexts?: FormTexts;
  illustration?: string;
  onClose: () => void;
  onSubmit: SubmitHandler<Data>;
  onSuccess?: () => void;
  policyLine?: React.ReactNode;
  processing?: boolean;
  reason?: CommunicationReason;
  section: string;
  subtitle?: ExtMessageDescriptor | React.ReactNode;
  success?: boolean;
  successIllustration?: string;
  title?: ExtMessageDescriptor;
};

export function CommunicationDialog<Data extends FormData>({
  reason,
  error,
  testId,
  title,
  fields,
  section,
  subtitle,
  policyLine,
  success,
  formTexts,
  processing,
  illustration,
  successIllustration,
  children,
  onClose,
  onSubmit,
  onSuccess,
}: Props<Data>): React.ReactElement {
  const appEnv = useAppEnv();
  const analytics = useAnalytics();
  const hasIllustration = Boolean(illustration);

  let formError;
  let errorVisible = false;

  if (error) {
    if (isFormValidationError(error)) {
      formError = error;
    } else {
      errorVisible = true;
    }
  }

  useEffect(() => {
    analytics.sendEvent({
      payload: {
        pageUrl: window.location.href,
        popupType: reason || UNKNOWN_REASON,
        section,
        source: appEnv.currentPageName,
      },
      type: 'popupOpen',
    });

    if (reason === CommunicationReason.CONSULTATION_CLAIM) {
      analytics.sendEvent({
        payload: {
          consultationType: 'popup',
          pageUrl: window.location.href,
          productId: appEnv.productId,
          source: appEnv.currentPageName,
        },
        type: 'consultationShow',
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const successVisible = Boolean(!errorVisible && success);
  const formVisible = !errorVisible && !successVisible;

  useEffect(() => {
    if (successVisible) {
      onSuccess?.();
    }
  }, [successVisible, onSuccess]);

  return (
    <Dialog onClose={onClose} testId={testId?.dialog} width={424} withCloseButton>
      {!hasIllustration && !successVisible && <DialogHeader testId={testId?.dialog} title={title} withBackButton />}
      {errorVisible && <ErrorView />}
      {formVisible && (
        <FormView<Data>
          error={formError}
          fields={fields}
          formTexts={formTexts}
          illustration={illustration}
          onSubmit={onSubmit}
          policyLine={policyLine}
          processing={processing}
          subtitle={subtitle}
          testId={testId?.formView}
          title={title}
        >
          {children}
        </FormView>
      )}
      {successVisible && (
        <SuccessView onOk={onClose} successIllustration={successIllustration} testId={testId?.successView} />
      )}
    </Dialog>
  );
}
