import { useState as p, useCallback as n } from "react";
import { useBooleanState as r } from "../useBooleanState/index.js";
function c(o = !1) {
  const [a, t] = p(), e = r(o), s = n(
    (u) => {
      t(u), e.setTrue();
    },
    [e.setTrue]
  ), l = n(() => {
    t(void 0), e.setFalse();
  }, [e.setFalse]);
  return {
    payload: a,
    isOpen: e.value,
    openWithPayload: s,
    close: l,
    open: e.setTrue,
    toggle: e.toggle
  };
}
function f({ children: o, initiallyOpen: a }) {
  const t = c(a);
  return o(t);
}
export {
  f as PopupState,
  c as usePopupState
};
