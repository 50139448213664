import {identity} from '@joomcode/deprecated-utils/function/identity';
import {assertNever} from '@joomcode/deprecated-utils/types';
import React, {useRef, useCallback, useState, useMemo} from 'react';
import {Selection, ChangeDetails} from '../common';
import {Mask} from '../Mask';
import {InputType, MaskOptions} from '../types';

type InputState = {
  selection: Selection;
  value: string;
};

export type UseMaskOptions = MaskOptions & {
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?(value: string): void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  transformBeforeInput?(value: string): string;
};

export type ReturnState = {
  maskRef: React.MutableRefObject<Mask>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  selection: Selection;
  updateValue(value: string): void;
  value: string;
};

export function useMask(mask: string, options: UseMaskOptions = {}): ReturnState {
  const {maskInstance, selectionInstance} = useMemo(
    () => ({
      maskInstance: new Mask(mask, options),
      selectionInstance: new Selection(0, 0),
    }),
    [mask, options],
  );

  const maskRef = useRef(maskInstance);
  const focusedRef = useRef<boolean>(false);
  const selectionBeforeRef = useRef(selectionInstance);
  const [input, setInput] = useState<InputState>(() => {
    if (maskRef.current.isEmpty()) {
      return {
        selection: maskRef.current.getSelection(),
        value: '',
      };
    }

    return {
      selection: maskRef.current.getSelection(),
      value: maskRef.current.toString(),
    };
  });

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (options.onKeyDown) {
        options.onKeyDown(event);
      }

      selectionBeforeRef.current.setSelection(
        event.currentTarget.selectionStart || 0,
        event.currentTarget.selectionEnd || 0,
      );
    },
    [options.onKeyDown], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {transformBeforeInput = identity} = options;
      const {selectionStart, selectionEnd, value} = event.target;
      const selectionAfter = new Selection(Number(selectionStart), Number(selectionEnd));

      const changeDetails = new ChangeDetails({
        selectionAfter,
        selectionBefore: selectionBeforeRef.current,
        valueAfter: value,
        valueBefore: maskRef.current.toString(),
      });

      maskRef.current.setSelection(selectionBeforeRef.current.start, selectionBeforeRef.current.end);

      switch (changeDetails.inputType) {
        case InputType.INSERT:
          maskRef.current.input(transformBeforeInput(changeDetails.insertedValue));
          break;
        case InputType.DELETE_CONTENT_BACKWARD:
          maskRef.current.deleteContentBackward();
          break;
        case InputType.DELETE_CHAR_BACKWARD:
          maskRef.current.deleteCharBackward();
          break;
        case InputType.DELETE_CHAR_FORWARD:
          maskRef.current.deleteCharForward();
          break;
        case InputType.NONE:
          break;
        default:
          assertNever(changeDetails.inputType);
      }

      const nextState: InputState = {
        selection: maskRef.current.getSelection(),
        value: maskRef.current.toString(),
      };

      setInput(nextState);

      if (options.onChange) {
        options.onChange(nextState.value);
      }
    },
    [options.onChange, options.transformBeforeInput], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      focusedRef.current = true;

      if (options.onFocus) {
        options.onFocus(event);
      }

      if (maskRef.current.isEmpty()) {
        maskRef.current.resetSelection();

        setInput({
          selection: maskRef.current.getSelection(),
          value: maskRef.current.toString(),
        });
      }
    },
    [options.onFocus], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      focusedRef.current = false;

      if (options.onBlur) {
        options.onBlur(event);
      }

      if (maskRef.current.isEmpty()) {
        const nextState = {
          selection: maskRef.current.getSelection(),
          value: '',
        };

        setInput(nextState);
        if (options.onChange) {
          options.onChange(nextState.value);
        }
      }
    },
    [options.onBlur, options.onChange], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const updateValue = useCallback(
    (value: string) => {
      const {transformBeforeInput = identity} = options;
      maskRef.current.setValue(transformBeforeInput(value));
      maskRef.current.resetSelection();

      let nextValue = value;
      if (value || (value === '' && focusedRef.current)) {
        nextValue = maskRef.current.toString();
      }

      setInput({
        selection: maskRef.current.getSelection(),
        value: nextValue,
      });
    },
    [options.transformBeforeInput], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return {
    maskRef,
    onBlur,
    onChange,
    onFocus,
    onKeyDown,
    selection: input.selection,
    updateValue,
    value: input.value,
  };
}
