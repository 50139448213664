import {FormApi} from 'final-form';
import {isString} from 'lib/guards';
import {useEffect} from 'react';

export function useExternalValues<Values>(form: FormApi<Values>, values?: Partial<Values>): void {
  useEffect(() => {
    if (values) {
      const registeredFields = form.getRegisteredFields();

      form.batch(() => {
        Object.keys(values).forEach((key) => {
          if (registeredFields.includes(key) && isString(values[key as keyof Values])) {
            form.change(key as keyof Values, values[key as keyof Values]);
          }
        });
      });
    }
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps
}
