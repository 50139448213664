import {useClient} from 'lib/client';
import {useEffect, useCallback} from 'react';

export const useVisibilityChangeEvent = () => {
  const {analytics} = useClient();

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'hidden') {
      analytics.dataLayer({
        event: 'session.visibility_hide_debug',
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
