import {config} from 'config';
import {isString} from 'lib/guards';
import {useLanguage} from 'lib/language/hooks';
import {transformPhoneBeforeInput} from 'lib/mask';
import React, {useCallback, useContext, useEffect, forwardRef} from 'react';
import {InputBlurHandler} from 'uikit/Input';
import {InputMask} from 'uikit/InputMask';
import {FormTextsContext, useFormStateContext, useFormActionsContext} from '../contexts';
import {InputField as InputFieldProps} from '../types';

const type = 'phone';

export type Props = Omit<InputFieldProps, 'type' | 'placeholder'>;

export const PhoneField = forwardRef((props: Props, ref: React.ForwardedRef<HTMLInputElement>): React.ReactElement => {
  const {autoFocus, autoComplete, label, minLength, name, onBlur, onFocus, onChange, testId, value = '', size} = props;

  const language = useLanguage();
  const texts = useContext(FormTextsContext);
  const {onFieldBlur, onFieldChange, addField, removeField} = useFormActionsContext();
  const {errors, values} = useFormStateContext();
  const phoneConfig = config.phone[language];

  const handleInputBlur = useCallback<InputBlurHandler>(
    (event) => {
      onFieldBlur({name: event.target.name, type});

      if (onBlur) {
        onBlur(event);
      }
    },
    [onFieldBlur, onBlur],
  );

  const handleInputMaskChange = useCallback(
    (inputValue: string) => {
      onFieldChange({name, type, value: inputValue});
    },
    [onFieldChange, type, name, onChange], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleTransformBeforeInput = useCallback(
    (inputValue: string) => {
      return transformPhoneBeforeInput(inputValue, phoneConfig);
    },
    [phoneConfig],
  );

  useEffect(() => {
    const fieldProps: InputFieldProps = {...props, type};
    addField(fieldProps);

    return () => removeField(fieldProps);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (name in values && values[name] === value) {
      return;
    }

    onFieldChange({name, type, value: value ?? ''});
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  // TODO: учесть, что value мб отсутствовать и нужно как-то инициализировать
  let inputValue;

  if (name in values) {
    inputValue = isString(values[name]) ? String(values[name]) : undefined;
  } else {
    inputValue = value;
  }

  const inputError = errors[name];

  return (
    <InputMask
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      disabled={props.disabled}
      error={inputError}
      label={label || texts?.labels?.[name]}
      mask={phoneConfig.mask}
      minLength={minLength}
      name={name}
      onBlur={handleInputBlur}
      onChange={handleInputMaskChange}
      onFocus={onFocus}
      ref={ref}
      size={size}
      testId={testId}
      transformBeforeInput={handleTransformBeforeInput}
      type={type}
      value={inputValue}
    />
  );
});
