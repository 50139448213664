import {identity} from '@joomcode/deprecated-utils/function';
import {useDefaultValidator} from 'lib/finalForm/hooks';
import {BaseFieldProps, ValidateHandler} from 'lib/finalForm/types';
import {getErrorMessage} from 'lib/finalForm/utils';
import {FieldValue} from 'lib/form/types';
import {buildMessage} from 'lib/intl';
import React, {useCallback} from 'react';
import {useField} from 'react-final-form';
import {useIntl} from 'react-intl';
import {Autocomplete, AutocompleteProps} from 'uikit/Autocomplete';
import {Checkbox} from 'uikit/Checkbox';
import styles from './index.module.scss';

export type AutocompleteFieldProps<
  Value extends FieldValue,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = Omit<BaseFieldProps<Value>, 'validate'> &
  Omit<AutocompleteProps<Value, true, DisableClearable, FreeSolo>, 'value' | 'onChange' | 'multiple'> & {
    validate?: ValidateHandler<Value[]>;
  };

export function AutocompleteMultiField<
  Value extends FieldValue,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  name,
  required,
  validate,
  requiredMessage,
  label,
  placeholder,
  ...props
}: AutocompleteFieldProps<Value, DisableClearable, FreeSolo>): React.ReactElement {
  const intl = useIntl();
  const handleValidate = useDefaultValidator({
    required,
    requiredMessage,
    validate,
  });
  const {input, meta} = useField<Value[]>(name, {
    // needs to avoid default `''` value
    // https://final-form.org/docs/react-final-form/types/FieldProps#format
    format: identity,

    validate: handleValidate,
  });
  const error = buildMessage(intl, getErrorMessage(meta));

  const onChange = useCallback(
    (_: unknown, newValue: (Value | string)[]) => input.onChange(newValue),
    [input.onChange], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Autocomplete<Value, true, DisableClearable, FreeSolo>
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      {...input}
      error={error}
      label={label}
      multiple
      onChange={onChange}
      placeholder={placeholder}
      // eslint-disable-next-line react/jsx-no-bind
      renderOption={(optionProps, option, {selected}) => (
        <li {...optionProps}>
          <Checkbox checked={selected} />
          <span className={styles.option}>{props.getOptionLabel?.(option) || option}</span>
        </li>
      )}
      value={input.value || []}
    />
  );
}
