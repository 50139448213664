import {useClient} from 'lib/client';
import {getPageNameByPathname} from 'lib/router';
import {parseUtmParameters} from 'lib/utm';
import {useRouter} from 'next/router';
import {useEffect, useCallback} from 'react';

type Props = {
  externalLink: string | undefined;
};

export const useInitialAnalyticsEvents = ({externalLink}: Props) => {
  const router = useRouter();
  const client = useClient();
  const {analytics} = client;

  const analyticsSessionStarted = useCallback(() => {
    analytics.sendEvent(
      {
        payload: {
          pageUrl: window.location.href,
        },
        type: 'sessionStart',
      },
      {immediately: true},
    );
    analytics.dataLayer({event: 'session.session_start'});

    if (externalLink) {
      analytics.sendEvent({
        payload: {
          link: externalLink,
          pageUrl: window.location.href,
          parsedUtm: parseUtmParameters(client.cookies.utmParameters),
          referrer: document.referrer,
        },
        type: 'externalLink',
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const analyticsPageLeave = useCallback(() => {
    analytics.dataLayer({
      event: 'pageLeave',
      locale: router.locale!,
      pagePath: router.asPath,
    });

    analytics.sendEvent({
      payload: {
        pageName: getPageNameByPathname(router.pathname),
        pageUrl: window.location.href,
      },
      type: 'pageLeave',
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const analyticsPageOpened = useCallback(() => {
    analytics.dataLayer({
      event: 'pageView',
      locale: router.locale!,
      pagePath: router.asPath,
    });

    analytics.sendEvent({
      payload: {
        pageName: getPageNameByPathname(router.pathname),
        pageUrl: window.location.href,
      },
      type: 'pageView',
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    analyticsSessionStarted();
    analyticsPageOpened();
    router.events.on('routeChangeStart', analyticsPageLeave);
    router.events.on('routeChangeComplete', analyticsPageOpened);

    return () => {
      router.events.off('routeChangeStart', analyticsPageLeave);
      router.events.off('routeChangeComplete', analyticsPageOpened);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
