export type LandingBrPayloadDevicevar = {
  countOfCategories: number;
  countOfProducts: number;
  countOfSuppliers: number;
  promotions: string[];
};

export type LandingBrConfig = {
  about: {
    news: {
      videoUrl: string;
    };
    show: boolean;
  };
  main: {
    cases: {comment: string; employee: string; job: string; video: string}[];
    catalogRedirect: boolean;
    news: {
      videoUrl: string;
    };
    show: boolean;
  };
};

export enum Devicevar {
  HOUSEHOLD_PROMOTION_ID = 'householdPromotionId',
  IS_CONTACT_US_PANEL_VISIBLE = 'isContactUsPanelVisible',
  IS_NON_CLOSING_REGISTRATION = 'isNonClosingRegistration',
  IS_VISIBLE_CONTINUE_REGISTRATION_BUTTON = 'isContinueRegistrationButtonVisible',
  LANDING_BR = 'landingBr122023',
  LANDING_BR_PAYLOAD = 'landingBrPayload',
  LANDING_CONFIG = 'landingConfig',
  LOYALTY_PROGRAM_AGREEMENT_RU_URL = 'loyaltyProgramAgreementRuUrl',
  MAIN_SELF_SERVICE_PROMOTIONS = 'mainSelfServicePromotions',
  MOQ = 'moq',
  PERSONAL_ACCOUNT_CLAIM_DOCUMENT_SAMPLE_URL = 'personalAccountClaimDocumentSampleUrl',
  PERSONAL_ACCOUNT_CLAIM_DOCUMENT_TEMPLATE_URL = 'personalAccountClaimDocumentTemplateUrl',
  PRODUCT_CARD_THREE_COLUMNS_LAYOUT = 'productCardThreeColumnsLayout',
  REFERRAL_PROGRAM_DOCUMENT_RU_URL = 'referralProgramDocumentRuUrl',
  REVERSE_MAIN_PAGE = 'reverseMainPage',
  SHOULD_HIDE_PRELIMINARY_PRICE_NOTIFICATION = 'shouldHidePreliminaryPriceNotification',
  SHOULD_HIDE_PRICE_PREFIX = 'shouldHidePricePrefix',
  SHOULD_REVERSE_REGISTRATION_FIELDS_OTIONS = 'shouldReverseRegistrationFieldsOptions',
  SHOULD_SHOW_REQUEST_QUOTE_BUTTON = 'shouldShowRequestQuoteButton',
  STARTING_FINANCING_PRICE = 'startingFinancingPrice',
  UNAUTH_USERS_LOCKED_FEATURES = 'unauthUsersLockedFeatures',
}

export type Devicevars = {
  [Devicevar.REVERSE_MAIN_PAGE]?: boolean;
  [Devicevar.HOUSEHOLD_PROMOTION_ID]?: string;
  [Devicevar.LOYALTY_PROGRAM_AGREEMENT_RU_URL]?: string;
  [Devicevar.REFERRAL_PROGRAM_DOCUMENT_RU_URL]?: string;
  [Devicevar.PERSONAL_ACCOUNT_CLAIM_DOCUMENT_SAMPLE_URL]?: string;
  [Devicevar.PERSONAL_ACCOUNT_CLAIM_DOCUMENT_TEMPLATE_URL]?: string;
  [Devicevar.LANDING_BR_PAYLOAD]?: LandingBrPayloadDevicevar;
  [Devicevar.PRODUCT_CARD_THREE_COLUMNS_LAYOUT]?: boolean;
  [Devicevar.LANDING_BR]?: 'A' | 'B' | 'C';
  [Devicevar.SHOULD_SHOW_REQUEST_QUOTE_BUTTON]?: boolean;
  [Devicevar.LANDING_CONFIG]?: LandingBrConfig;
  [Devicevar.UNAUTH_USERS_LOCKED_FEATURES]?: boolean;
  [Devicevar.IS_NON_CLOSING_REGISTRATION]?: boolean;
  [Devicevar.MOQ]?: boolean;
  [Devicevar.IS_VISIBLE_CONTINUE_REGISTRATION_BUTTON]?: boolean;
  [Devicevar.IS_CONTACT_US_PANEL_VISIBLE]?: boolean;
  [Devicevar.SHOULD_REVERSE_REGISTRATION_FIELDS_OTIONS]?: boolean;
  [Devicevar.STARTING_FINANCING_PRICE]?: number;
  [Devicevar.SHOULD_HIDE_PRELIMINARY_PRICE_NOTIFICATION]?: boolean;
  [Devicevar.SHOULD_HIDE_PRICE_PREFIX]?: boolean;
};

export type DevicevarsInfo = {
  configId: string;
  devicevars: Devicevars;
};
