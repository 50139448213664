import {useUpdateEffect} from '@joomcode/deprecated-utils/react/useUpdateEffect';
import {Auth} from 'components/Auth';
import {AuthByPhoneViewName, AuthOrigin, AuthViewName} from 'components/Auth/types';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import {useDevicevars} from 'lib/devicevars';
import {useEvent, useStore} from 'lib/effector';
import {useLanguage} from 'lib/language/hooks';
import {Language} from 'lib/language/types';
import omit from 'lodash/omit';
import {
  $authDialog,
  changeAuthDialogView,
  changeAuthPhoneDialogView,
  closeAuthDialog,
  authDialogSignInByEmailFx,
  authDialogSignUpByEmailFx,
  authDialogRequestRecoveryFx,
  authDialogSendOtpFx,
  authDialogSignInByPhoneFx,
  openAuthDialog,
} from 'models/auth/dialog';
import {useRouter} from 'next/router';
import React, {useCallback, useEffect} from 'react';
import {rootTestId} from 'testId';
import {Button} from 'uikit/Button';
import {Dialog, DialogContent} from 'uikit/Dialog';
import {usePreliminaryAuth} from './hooks';
import {messages} from './messages';

export function AuthDialog(): React.ReactElement | null {
  const appEnv = useAppEnv();
  const analytics = useAnalytics();
  const authState = useStore($authDialog);
  const router = useRouter();
  const language = useLanguage();
  const {isNonClosingRegistration} = useDevicevars();

  usePreliminaryAuth();

  const handleViewChange = useEvent(changeAuthDialogView);
  const handlePhoneViewChange = useEvent(changeAuthPhoneDialogView);
  const handleClose = useEvent(closeAuthDialog);
  const handleSignInByEmail = useEvent(authDialogSignInByEmailFx);
  const handleSignUpByEmail = useEvent(authDialogSignUpByEmailFx);
  const handleRequestRecovery = useEvent(authDialogRequestRecoveryFx);
  const handleSignInByPhone = useEvent(authDialogSignInByPhoneFx);
  const handleSendOtpFx = useEvent(authDialogSendOtpFx);
  const handleBack = useCallback(() => {
    handlePhoneViewChange(AuthByPhoneViewName.PHONE);
  }, [handlePhoneViewChange]);
  const handleOpenDialog = useEvent(openAuthDialog);

  const onClose = useCallback(() => {
    !isNonClosingRegistration && handleClose();
  }, [handleClose]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const {authView} = router.query;
    if (typeof authView === 'string' && language !== Language.RU) {
      handleOpenDialog({
        origin: AuthOrigin.SELF_SERVICE,
        source: appEnv.currentPageName,
        view: authView in AuthViewName ? (authView as AuthViewName) : AuthViewName.SIGN_IN_BY_PHONE,
      });
      router.replace({pathname: router.pathname, query: omit(router.query, 'authView')}, undefined, {shallow: true});
    }
  }, [router, language]); // eslint-disable-line react-hooks/exhaustive-deps

  const withBackButton =
    authState.view === AuthViewName.SIGN_IN_BY_PHONE && authState.phoneView === AuthByPhoneViewName.OTP;

  const requestRecoverySentAction = (
    <Button
      color='black'
      label={messages.button}
      onClick={handleClose}
      testId={rootTestId?.authDialog.recoverySuccessSentAction}
    />
  );

  useUpdateEffect(() => {
    if (authState.visible) {
      analytics.dataLayer({
        event: 'authDialogOpen',
        source: appEnv.currentPageName,
        view: authState.view,
      });
    } else {
      analytics.dataLayer({
        event: 'authDialogClose',
        source: appEnv.currentPageName,
        view: authState.view,
      });
    }
  }, [authState.visible]);

  if (!(authState.visible && authState.view)) {
    return null;
  }

  return (
    <Dialog
      onBack={handleBack}
      onClose={onClose}
      width={424}
      withBackButton={withBackButton}
      withCloseButton={!isNonClosingRegistration}
    >
      <DialogContent>
        <Auth
          onPhoneViewChange={handlePhoneViewChange}
          onRequestRecovery={handleRequestRecovery}
          onSendOtp={handleSendOtpFx}
          onSignInByEmail={handleSignInByEmail}
          onSignInByPhone={handleSignInByPhone}
          onSignUpByEmail={handleSignUpByEmail}
          onViewChange={handleViewChange}
          origin={authState.origin}
          phoneView={authState.phoneView}
          requestRecoveryEmail={authState.requestRecoveryEmail}
          requestRecoveryError={authState.requestRecoveryError}
          requestRecoveryPending={authState.requestRecoveryPending}
          requestRecoverySentAction={requestRecoverySentAction}
          retryAfterMs={authState.retryAfterMs}
          sendOtpData={authState.sendOtpData}
          sendOtpError={authState.sendOtpError}
          sendOtpLoading={authState.sendOtpLoading}
          signInError={authState.signInError}
          signInPending={authState.signInPending}
          signUpError={authState.signUpError}
          signUpPending={authState.signUpPending}
          testId={rootTestId?.authDialog}
          view={authState.view}
        />
      </DialogContent>
    </Dialog>
  );
}
