import {useEvent} from 'lib/effector';
import {openTimeoutedRegistation as openTimeoutedRegistationEvent} from 'models/ssRegistrationDialog/timeoutedRegistration';
import {useEffect, useRef} from 'react';

type Props = {enabled: boolean};

export const useRegistrationTimeout = ({enabled}: Props) => {
  const openTimeoutedRegistation = useEvent(openTimeoutedRegistationEvent);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (!enabled) {
      return;
    }

    timeoutRef.current = setTimeout(() => {
      openTimeoutedRegistation({
        pageUrl: window.location.href,
        source: 'openTimeoutedRegistation',
      });
    }, 1_000);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [enabled]); // eslint-disable-line react-hooks/exhaustive-deps
};
