import {SelectField, SelectFieldProps} from 'components/FinalForm/SelectMuiField';
import {formatAnnualTurnoverRange} from 'lib/companyAnnualTurnover/utils';
import {useDevicevars} from 'lib/devicevars';
import {useEvent, useStore} from 'lib/effector';
import {formatCurrency} from 'lib/pricing';
import {
  $companyAnnualTurnoverCollection,
  loadCompanyAnnualTurnover as loadCompanyAnnualTurnoverEvent,
} from 'models/companyAnnualTurnover';
import {$user} from 'models/user';
import React, {useEffect, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {fieldMessages} from '../../messages';
import {FieldProps} from '../../types';

export const CompanyAnnualTurnoverRangeLevelField = ({fieldName, required}: FieldProps) => {
  const intl = useIntl();
  const companyAnnualTurnoverState = useStore($companyAnnualTurnoverCollection);
  const user = useStore($user);
  const loadCompanyAnnualTurnover = useEvent(loadCompanyAnnualTurnoverEvent);
  const companyAnnualTurnover = user.country ? companyAnnualTurnoverState.byId[user.country] : undefined;
  const isLoading = companyAnnualTurnoverState.loading;
  const {shouldReverseRegistrationFieldsOptions} = useDevicevars();

  useEffect(() => {
    if (user.country && !companyAnnualTurnover) {
      loadCompanyAnnualTurnover({countryCode: user.country});
    }
  }, [user.country]); // eslint-disable-line react-hooks/exhaustive-deps

  const options: SelectFieldProps<string>['options'] = useMemo(() => {
    if (!companyAnnualTurnover) {
      return [];
    }

    const ranges = shouldReverseRegistrationFieldsOptions
      ? companyAnnualTurnover.ranges.slice().reverse()
      : companyAnnualTurnover.ranges;

    return ranges.map((range) => ({
      label: formatAnnualTurnoverRange(range, companyAnnualTurnover.currency, intl),
      value: range.name,
    }));
  }, [companyAnnualTurnover, intl, shouldReverseRegistrationFieldsOptions]);

  return (
    <SelectField
      disabled={!companyAnnualTurnover || isLoading}
      label={intl.formatMessage(fieldMessages.companyTurnoverRangeLevel, {
        currency: companyAnnualTurnover ? formatCurrency(intl, companyAnnualTurnover.currency) : undefined,
      })}
      name={fieldName}
      options={options}
      required={required}
    />
  );
};
