import {isFunction} from 'lib/guards';
import {ExtMessageDescriptor} from 'lib/intl/types';
import {useCallback} from 'react';
import {messages} from '../messages';
import {ValidateHandler, Validator} from '../types';

type Props<T> = {
  required?: boolean;
  requiredMessage?: ExtMessageDescriptor;
  validate?: ValidateHandler<T>;
};

export function useAsyncValidator<T>({
  requiredMessage = messages.required,
  required,
  validate,
}: Props<T>): Validator<T> {
  return useCallback(
    async (value) => {
      if (required) {
        if (!value) {
          return requiredMessage;
        }
      }

      if (isFunction(validate)) {
        return validate(value);
      }

      return undefined;
    },
    [required, validate], // eslint-disable-line react-hooks/exhaustive-deps
  );
}
