import {getDefaultBaseUrl} from 'lib/app/getDefaultBaseUrl';
import {BaseUrlContext} from 'lib/baseUrl';
import React, {useMemo} from 'react';

type Props = {
  baseUrl: URL | undefined;
  children: React.ReactNode;
};

export const ErrorPage = (props: Props) => {
  const baseUrl: URL = useMemo(() => {
    try {
      if (!props.baseUrl || !(props.baseUrl instanceof URL)) {
        throw new Error('BaseUrl is not prepared');
      }
      return props.baseUrl;
    } catch (e) {
      return getDefaultBaseUrl();
    }
  }, [props.baseUrl]);

  return <BaseUrlContext.Provider value={baseUrl}>{props.children}</BaseUrlContext.Provider>;
};
