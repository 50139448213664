import {AutocompleteMultiField} from 'components/FinalForm/AutocompleteMultiField';
import {Category} from 'lib/category/types';
import {useDevicevars} from 'lib/devicevars';
import {useEvent, useStore} from 'lib/effector';
import {loadRootCategoriesFx, $categories} from 'models/categories';
import React, {useCallback, useMemo, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {fieldMessages} from '../../messages';
import {FieldProps} from '../../types';

const DEFAULT_CATEGORIES: Category[] = [];

export const CategoryIds = ({fieldName, required}: FieldProps) => {
  const intl = useIntl();
  const {categories = DEFAULT_CATEGORIES, loading} = useStore($categories);
  const {shouldReverseRegistrationFieldsOptions} = useDevicevars();

  const loadCategories = useEvent(loadRootCategoriesFx);
  useEffect(() => {
    loadCategories();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const options = (shouldReverseRegistrationFieldsOptions ? categories.slice().reverse() : categories).map(
    (item) => item.id,
  );

  const categoryById = useMemo(() => {
    const result: Record<string, Category> = {};
    for (const category of categories) {
      result[category.id] = category;
    }
    return result;
  }, [categories]);
  const getOptionLabel = useCallback((id: string) => categoryById[id]?.name || id, [categoryById]);

  return (
    <AutocompleteMultiField
      disableClearable
      freeSolo
      getOptionLabel={getOptionLabel}
      label={intl.formatMessage(fieldMessages.categoryIds)}
      loading={loading}
      name={fieldName}
      options={options || []}
      required={required}
    />
  );
};
