import {OnErrorFn} from '@formatjs/intl';
import {config} from 'config';
import {Language} from 'lib/language/types';
import {intlLogger} from 'lib/logger';

type Messages = Record<string, string>;

export async function loadMessages(language: Language): Promise<Messages> {
  let messages;

  try {
    const locale = config.i18nLocaleByLanguage[language];
    if (!locale) {
      throw new Error(`Cannot find translations file for language ${language}`);
    }

    /* eslint-disable import/no-named-as-default-member */
    const clientMessages = (await import(`../../translations/${locale}.json`)).default;
    const libsMessages = (await import(`../../libs-translations/${locale}.ts`)).default;
    /* eslint-enable import/no-named-as-default-member */

    messages = {...clientMessages, ...libsMessages};
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    messages = {};
  }

  return messages;
}

export const handleIntlError: OnErrorFn = (error) => {
  if (__DEVELOPMENT__) {
    if (error.code === 'MISSING_TRANSLATION') {
      return;
    }
  }
  intlLogger.error('IntlProvider', error);
};
