import {StyledEngineProvider} from '@mui/material/styles';
import {Perfomance} from 'components/Perfomance';
import {Toaster} from 'components/Toaster';
import {AuthDialog} from 'containers/AuthDialog';
import {ConsultationDialog} from 'containers/ConsultationDialog';
import {ContactUsRequestDialog} from 'containers/ContactUsRequestDialog';
import {LoyaltyConsultationDialog} from 'containers/LoyaltyConsultationDialog';
import {ModalsManagerProvider} from 'containers/ModalsManagerProvider';
import {PartnersConsultationDialog} from 'containers/PartnersConsultationDialog';
import {PaymentsConsultationDialog} from 'containers/PaymentsConsultationDialog';
import {PaymentsPartnersDialog} from 'containers/PaymentsPartnersDialog';
import {PromocodeContextProvider} from 'containers/PromocodeContextProvider';
import {ReferralDialog} from 'containers/ReferralDialog';
import {RegistrationDialog} from 'containers/RegistrationDialog';
import {AnalyticsContext} from 'lib/analytics';
import {RootHooks} from 'lib/app/RootHooks';
import {BaseUrlContext} from 'lib/baseUrl';
import {Client, ClientContext} from 'lib/client';
import {DevicevarsProvider} from 'lib/devicevars';
import {StateManager, StateManagerProvider} from 'lib/effector';
import Head from 'next/head';
import React from 'react';

type Props = {
  children: React.ReactNode;
  client: Client;
  externalLink: string | undefined;
  store: StateManager;
  timeoutedRegistrationEnabled: boolean;
};

export const BasePage = ({children, client, store, externalLink, timeoutedRegistrationEnabled}: Props) => {
  return (
    <ClientContext.Provider value={client}>
      {!__DEVELOPMENT__ && <Perfomance />}
      <BaseUrlContext.Provider value={client.config.baseUrl}>
        <DevicevarsProvider>
          <StateManagerProvider value={store}>
            <RootHooks externalLink={externalLink} timeoutedRegistrationEnabled={timeoutedRegistrationEnabled} />
            <PromocodeContextProvider>
              <ModalsManagerProvider>
                <AnalyticsContext.Provider value={client.analytics}>
                  <StyledEngineProvider injectFirst>
                    <Head>
                      <meta content='#ffffff' name='theme-color' />
                      <link href='/ico/pro/favicon-96x96.png' rel='icon' sizes='96x96' type='image/png' />
                      <link href='/ico/pro/apple-icon-180x180.png' rel='apple-touch-icon' sizes='180x180' />
                      <link href='/ico/pro/manifest.json' rel='manifest' />
                    </Head>
                    <Toaster>
                      {children}
                      <AuthDialog />
                      <ConsultationDialog />
                      <PartnersConsultationDialog />
                      <PaymentsConsultationDialog />
                      <PaymentsPartnersDialog />
                      <LoyaltyConsultationDialog />
                      <RegistrationDialog />
                      <ContactUsRequestDialog />
                      <ReferralDialog />
                    </Toaster>
                  </StyledEngineProvider>
                </AnalyticsContext.Provider>
              </ModalsManagerProvider>
            </PromocodeContextProvider>
          </StateManagerProvider>
        </DevicevarsProvider>
      </BaseUrlContext.Provider>
    </ClientContext.Provider>
  );
};
